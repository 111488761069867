
import router from "@/router";
import { Actions } from "@/store/enums/StoreEnums";
import { defineComponent, ref, onMounted, computed, watch, reactive } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { detectSubDomain } from "@/core/helpers/helps";
import { useI18n } from 'vue-i18n';
import { toastSuccess, toastError } from "@/core/services/Alert";
import type { ElForm } from 'element-plus';
import { ElMessage } from 'element-plus';

export default defineComponent({
  name: "profile-edit",
  setup(prop, context) {
    const { t } = useI18n();
    const approvers = ref([]);
    const lists: any = ref([]);
    const drag = ref(false);
    const store = useStore();
    const divisis = ref([]);
    const divisi = ref("");
    const direktorats = ref([]);
    const direktorat = ref("");
    const filtersC = computed(() => store.getters.getFilters);
    const cFilter = ref({
      page: filtersC.value.page,
      itemsPerPage: 100,
      trash: filtersC.value.trash,
    });

    const route = useRoute();
    const getChannel = computed(() => {
      return store.getters.getListChannel;
    });
    const bumnSelected: any = ref([])

    const form = computed(() => {
      return store.getters.getformProfile;
    });

    const loading = ref(false)

    onMounted(() => {
      store.dispatch(Actions.GET_CHANNEL_LIST)
      store.dispatch(Actions.GET_PROFILE_DETAIL).then((res) => {
        form.value.nip = res.nip
        form.value.name = res.name;
        form.value.username = res.username;
        form.value.email = res.email;
        form.value.phone = res.phone;
        form.value._id = res._id
        bumnSelected.value = res.tenants[0].workspaces[0].channels
      })
    });

    const rules = reactive({
      name: [
        { required: true, message: t('roleAndPermissionPage.textRequired', { attr : t('signUpPage.formLabelName')}), trigger: 'blur', pattern: /^[^<>?!]*$/ },
      ],
      username: [ 
        { required: true, message: t('roleAndPermissionPage.textRequired', { attr : t('signUpPage.formLabelUsername')}), trigger: 'blur' },
      ],
      nip: [
        {
          required: true,
          message: t('roleAndPermissionPage.textRequired', { attr : t('roleAndPermissionPage.textRequired', { attr : t('signUpPage.formLabelNIP')}) }),
          trigger: 'blur',
        },
      ],
      phone: [
        { required: true, message: t('roleAndPermissionPage.textRequired', { attr : t('signUpPage.formLabelPhone')}), trigger: 'blur' },
        { pattern: /^0\d{1,12}$/, message: t('signUpPage.formPhone'), trigger: 'change' },
      ],
      email: [
        {
          required: true,
          type: 'email',
          message: t('roleAndPermissionPage.textRequired', { attr : t('signUpPage.formLabelEmail')}),
          trigger: 'blur',
        },
      ],
    })

    const formRef = ref<InstanceType<typeof ElForm>>();

    const submitButton = ref<HTMLElement | null>(null);

    const editProfile = (event) => {
      event.preventDefault();
      if (!formRef.value) return;
      loading.value = true
      formRef.value.validate((valid, fields) => {
        submitButton.value?.setAttribute('data-kt-indicator', 'on');
        if(valid) {
          const payload = {
            "name": form.value.name,
            "phone": form.value.phone,
            "channel_id": bumnSelected.value[0]._id,
            "nip": form.value.nip
          }
          store
            .dispatch(Actions.UPDATE_PROFILE_USER, payload)
            .then((response) => {
              toastSuccess(`${t('common.success')} ${t('common.update')} ${t('membersPage.textMembers')}`)
              loading.value = false
              router.push({ name: "profile-detail" })
            })
            .catch((errors) => {
              console.log("value error", errors);
              loading.value = false
              toastError(`${t('common.failed')} ${t('common.update')} ${t('membersPage.textMembers')}`)
            })
        } else {
          ElMessage({
              message: t('signUpPage.unvalid'),
              type: 'error',
            });
          loading.value = false
        }
        submitButton.value?.setAttribute('data-kt-indicator', 'off');
      })
    }

    watch(
      () => form.value.phone,
      (after, before) => {
        if (after.match(/^-\d*\.?\d+$/)) {
          form.value.phone = after.split("-")[1]
        }
      }
    );

    const EditProfile = route => {
      if (route == "profile-detail") {
        router.push({ name: "profile-edit" })
      }
    }

    const getProfile = computed(() => {
      return store.getters.getUserProfile;
    });

    return {
      detectSubDomain,
      divisi,
      divisis,
      direktorat,
      direktorats,
      EditProfile,
      route,
      form,
      bumnSelected,
      getChannel,
      editProfile,
      rules,
      t,
      formRef,
      loading,
      getProfile
    };
  },
});
